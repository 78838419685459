function removeLastSlash(str = '') {
  return str.replace(/\/$/, '');
}

// NN api url
if (!process.env.NX_API_URL) {
  throw new Error('API URL is not specified');
}
export const ApiUrl = process.env.NX_API_URL;
// api version
export const ApiAppVersion = process.env.NX_API_VERSION || '3.0.0';
// base path to assets on S3
export const AssetsBaseUrl = removeLastSlash(process.env.NX_ASSETS_URL);
// base url to locales bucket on S3
export const LocalesBaseUrl = removeLastSlash(process.env.NX_LOCALES_URL);
// allowed backUrl whitelist
export const BackUrlWhitelist = process.env.NX_URL_WHITELIST || '';
// url to edison project
export const EdisonPath = removeLastSlash(process.env.NX_EDISON_URL);
// url to legacy project
export const LegacyPath = removeLastSlash(process.env.NX_LEGACY_URL);
// url to onboarding app
export const OnboardingAppPath = removeLastSlash(process.env.NX_SSO_ONBOARDING_URL);
// url to assessment part of the onboarding app
export const OnboardingAppAssessmentPath = `${OnboardingAppPath}/assessment`;
// url to training app
export const TrainingAppPath = removeLastSlash(process.env.NX_SSO_TRAINING_URL);
// url to subscribe app
export const SubscribeAppPath = removeLastSlash(process.env.NX_SSO_SUBSCRIBE_URL);
// url to the blog
export const BlogPath = removeLastSlash(process.env.NX_BLOG_URL);
// onboarding url whitelist
export const OnboardingUrlWhitelist = process.env.NX_ONBOARDING_URL_WHITELIST || '';
// sso remote config name
export const RemoteConfigSSO = process.env.NX_REMOTE_CONFIG_SSO || 'web_sso';
export const IsDebug = !!process.env.NX_DEBUG;
// passkey feature toggle
export const PasskeyOn = (process.env.NX_SSO_PASSKEY ?? 'off') === 'on';

const BUILD_ID_FALLBACK = 'local-build';
export const BuildID = process.env.NX_BUILD_ID || BUILD_ID_FALLBACK;

// Firebase config object
export let FirebaseConfig;
if (process.env.NODE_ENV === 'test') {
  FirebaseConfig = {
    apiKey: 'mock-api-key',
    authDomain: 'mock-auth-domain',
    databaseURL: 'mock-database-url',
    projectId: 'mock-project-id',
    storageBucket: 'mock-storage-bucket',
    messagingSenderId: 'mock-messaging-sender-id',
    appId: 'mock-app-id',
    measurementId: 'mock-measurement-id',
  };
} else {
  FirebaseConfig = {
    apiKey: process.env.NX_FIREBASE_API_KEY || '',
    authDomain: process.env.NX_FIREBASE_DOMAIN,
    databaseURL: process.env.NX_FIREBASE_URL,
    projectId: process.env.NX_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.NX_FIREBASE_BUCKET,
    messagingSenderId: process.env.NX_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.NX_FIREBASE_APP_ID || '',
    measurementId: process.env.NX_FIREBASE_MEASUREMENT_ID || '',
  };
}
