import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import ExperimentsService from './experimentsService';
import * as Selectors from './selectors';
import { ExperimentsActions, initExperimentsPayload } from './slice';

export function* initExperimentsSaga(action: PayloadAction<initExperimentsPayload>) {
  const { experiments } = action.payload;

  yield call(ExperimentsService.init, ApiService);
  const response: TApiFetchResponse<typeof ExperimentsService.getExperiments> = yield call(
    ExperimentsService.getExperiments,
    experiments
  );
  yield put(ExperimentsActions.experimentsReady(response.data));
}

export function* waitForExperimentsFeature() {
  const loading: boolean = yield select(Selectors.isLoading);
  if (loading) {
    yield take(ExperimentsActions.experimentsReady);
  }
}

export default function* experimentsWatcher() {
  yield all([takeEvery(ExperimentsActions.initExperiments, initExperimentsSaga)]);
}
