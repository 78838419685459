import { stringToBase64URL } from '../../common/base64String';
import { isLocalhost, isProduction } from '../../common/utils';
import {
  AuthenticationCredential,
  AuthenticationResponseJSON,
  AuthenticatorAttachment,
  AuthenticatorTransportFuture,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialDescriptorJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationCredential,
  RegistrationResponseJSON,
} from './types';

// this can be moved to an env variable but in this case we will need to
// propagate the value with init action. we can consider implementing this
// in future though
const ORIGIN = isProduction() ? 'https://api.neuronation.com' : 'https://api.dev.neuronation.com';

export function base64URLStringToBuffer(base64Url: string): ArrayBuffer {
  // Convert from Base64URL to Base64
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  /**
   * Pad with '=' until it's a multiple of four
   * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
   * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
   * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
   * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
   */
  const padLength = (4 - (base64.length % 4)) % 4;
  const padded = base64.padEnd(base64.length + padLength, '=');
  const binary = window.atob(padded);
  const buffer = new ArrayBuffer(binary.length);
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return buffer;
}

function bufferToString(buffer: ArrayBuffer): string {
  const bytes = new Uint8Array(buffer);
  let str = '';

  for (const charCode of bytes) {
    str += String.fromCharCode(charCode);
  }
  return str;
}

function bufferToJson(buffer: ArrayBuffer): any {
  const str = bufferToString(buffer);
  return JSON.parse(str);
}

export function bufferToBase64URLString(buffer: ArrayBuffer): string {
  const str = bufferToString(buffer);

  return stringToBase64URL(str);
}

function toPublicKeyCredentialDescriptor(descriptor: PublicKeyCredentialDescriptorJSON): PublicKeyCredentialDescriptor {
  const { id } = descriptor;
  return {
    ...descriptor,
    id: base64URLStringToBuffer(id),
    /**
     * `descriptor.transports` is an array of our `AuthenticatorTransportFuture` that includes newer
     * transports that TypeScript's DOM lib is ignorant of. Convince TS that our list of transports
     * are fine to pass to WebAuthn since browsers will recognize the new value.
     */
    transports: descriptor.transports as AuthenticatorTransport[],
  };
}

const attachments: AuthenticatorAttachment[] = ['cross-platform', 'platform'];

/**
 * If possible coerce a `string` value into a known `AuthenticatorAttachment`
 */
function toAuthenticatorAttachment(attachment: string | null): AuthenticatorAttachment | undefined {
  if (!attachment) {
    return;
  }

  if (attachments.indexOf(attachment as AuthenticatorAttachment) < 0) {
    return;
  }

  return attachment as AuthenticatorAttachment;
}

export function convertCreationOptions(optionsJSON: PublicKeyCredentialCreationOptionsJSON): CredentialCreationOptions {
  // We need to convert some values to Uint8Arrays before passing the credentials to the navigator
  const publicKey: PublicKeyCredentialCreationOptions = {
    ...optionsJSON,
    challenge: base64URLStringToBuffer(optionsJSON.challenge),
    user: {
      ...optionsJSON.user,
      id: base64URLStringToBuffer(optionsJSON.user.id),
    },
    rp: {
      ...optionsJSON.rp,
      id: isLocalhost ? 'localhost' : optionsJSON.rp.id,
    },
    excludeCredentials: optionsJSON.excludeCredentials?.map(toPublicKeyCredentialDescriptor),
    timeout: 60000,
  };
  // Finalize options
  const options: CredentialCreationOptions = { publicKey };
  return options;
}

export function parseRegistrationResponse(credential: RegistrationCredential): RegistrationResponseJSON {
  const { id, rawId, response, type } = credential;
  // Continue to play it safe with `getTransports()` for now, even when L3 types say it's required
  let transports: AuthenticatorTransportFuture[] | undefined = undefined;
  if (typeof response.getTransports === 'function') {
    transports = response.getTransports();
  }
  // L3 says this is required, but browser and webview support are still not guaranteed.
  let responsePublicKeyAlgorithm: number | undefined = undefined;
  if (typeof response.getPublicKeyAlgorithm === 'function') {
    responsePublicKeyAlgorithm = response.getPublicKeyAlgorithm();
  }

  let responsePublicKey: string | undefined = undefined;
  if (typeof response.getPublicKey === 'function') {
    const _publicKey = response.getPublicKey();
    if (_publicKey !== null) {
      responsePublicKey = bufferToBase64URLString(_publicKey);
    }
  }

  // L3 says this is required, but browser and webview support are still not guaranteed.
  let responseAuthenticatorData: string | undefined;
  if (typeof response.getAuthenticatorData === 'function') {
    responseAuthenticatorData = bufferToBase64URLString(response.getAuthenticatorData());
  }

  const { attestationObject, clientDataJSON } = response;
  const clientData = bufferToJson(clientDataJSON);
  clientData.origin = ORIGIN;

  return {
    id,
    rawId: bufferToBase64URLString(rawId),
    response: {
      attestationObject: bufferToBase64URLString(attestationObject),
      clientDataJSON: stringToBase64URL(JSON.stringify(clientData)),
      transports,
      publicKeyAlgorithm: responsePublicKeyAlgorithm,
      publicKey: responsePublicKey,
      authenticatorData: responseAuthenticatorData,
    },
    type,
    clientExtensionResults: credential.getClientExtensionResults(),
    authenticatorAttachment: toAuthenticatorAttachment(credential.authenticatorAttachment),
  };
}

export function convertCredentialRequest(optionsJSON: PublicKeyCredentialRequestOptionsJSON): CredentialRequestOptions {
  // We need to avoid passing empty array to avoid blocking retrieval
  // of public key
  let allowCredentials;
  if (optionsJSON.allowCredentials?.length !== 0) {
    allowCredentials = optionsJSON.allowCredentials?.map(toPublicKeyCredentialDescriptor);
  }
  // We need to convert some values to Uint8Arrays before passing the credentials to the navigator
  const publicKey: PublicKeyCredentialRequestOptions = {
    ...optionsJSON,
    challenge: base64URLStringToBuffer(optionsJSON.challenge),
    allowCredentials,
    rpId: isLocalhost ? 'localhost' : optionsJSON.rpId,
    timeout: 60000,
  };

  // Prepare options for `.get()`
  const options: CredentialRequestOptions = {
    publicKey,
  };
  return options;
}

export function parseAuthenticationResponse(credential: AuthenticationCredential): AuthenticationResponseJSON {
  const { id, rawId, response, type } = credential;

  const { authenticatorData, clientDataJSON, signature, userHandle } = response;
  console.log('clientDataJSON', clientDataJSON);
  const clientData = bufferToJson(clientDataJSON);
  clientData.origin = ORIGIN;
  console.log('clientData', clientData);

  // Convert values to base64 to make it easier to send back to the server
  return {
    id,
    rawId: bufferToBase64URLString(rawId),
    response: {
      authenticatorData: bufferToBase64URLString(authenticatorData),
      clientDataJSON: stringToBase64URL(JSON.stringify(clientData)),
      signature: bufferToBase64URLString(signature),
      userHandle: userHandle ? bufferToBase64URLString(userHandle) : undefined,
    },
    type,
    clientExtensionResults: credential.getClientExtensionResults(),
    authenticatorAttachment: toAuthenticatorAttachment(credential.authenticatorAttachment),
  };
}

export function getOSName(ua: string): string {
  if (ua.includes('Win')) return 'Windows OS';
  if (ua.includes('like Mac')) return 'iOS';
  if (ua.includes('Mac')) return 'Macintosh';
  if (ua.includes('Android')) return 'Android OS';
  if (ua.includes('Linux')) return 'Linux OS';

  return 'Unknown OS';
}

function getBrowserName(userAgent: string) {
  if (userAgent.indexOf('Edg') > -1) {
    return 'Microsoft Edge';
  } else if (userAgent.indexOf('OPR') > -1) {
    return 'Opera';
  } else if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome';
  } else if (userAgent.indexOf('Firefox') > -1) {
    return 'Firefox';
  } else if (userAgent.indexOf('Safari') > -1) {
    return 'Safari';
  } else if (userAgent.indexOf('Trident') > -1 || userAgent.indexOf('MSIE') > -1) {
    return 'Internet Explorer';
  }
  return 'Unknown';
}

export function getDeviceName() {
  const userAgent = window.navigator.userAgent;
  const browserName = getBrowserName(userAgent);
  const osName = getOSName(userAgent);
  return `Browser ${browserName} on ${osName}`;
}
