import { NetworkError, SharedErrors } from 'web_core_library';
import { LanguageService } from '../language';
import { NotificationActions } from './actions';

export default function parseError(err: SharedErrors.BaseError | Error) {
  if (!SharedErrors.isBaseError(err)) {
    // for unexpected non internal errors - show alert always
    return NotificationActions.showError(err.message);
  }
  if (err instanceof NetworkError) {
    // show predefined message for network error
    const t = LanguageService.getTFunction();
    return NotificationActions.showError(t('common.common:Errors.HttpUnknown'));
  }
  const msg = `There is an error in ${err.feature}: ${err.message}`;
  if (err.errorType === SharedErrors.ALERT_TYPE) {
    return NotificationActions.showError(msg);
  } else {
    return false;
  }
}
