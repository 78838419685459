import { CombinedState } from '@reduxjs/toolkit';
import * as Reducer from './slice';

export interface IState extends CombinedState<unknown> {
  // this feature state
  [Reducer.statePropName]: Reducer.IPasskeyAuthState;
}

const getFeatureState = (state: IState) => state[Reducer.statePropName];

export const isPasskeyStarted = (state: IState) => getFeatureState(state).started;

export const isPasskeyReady = (state: IState) => getFeatureState(state).ready;

export const isPasskeyEnabled = (state: IState) => isPasskeyStarted(state) && isPasskeyReady(state);

export const isPasskeyLoading = (state: IState) => getFeatureState(state).loading;

export const getLastPasskeyError = (state: IState) => getFeatureState(state).error;

export const getListOfPasskeys = (state: IState) => getFeatureState(state).passkeys;
