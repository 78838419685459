import LanguageSaga from './saga';
import * as LanguageSelectors from './selectors';
import LanguageService from './service';
import * as LanguageReducer from './slice';
import { LanguageActions } from './slice';
import { ELanguage, TFunction } from './types';
import useDocumentLang from './useDocumentLang';
import useTranslation, { LanguageProvider } from './useTranslation';
import { ETagType, parseMultilineLoca, renderMultilineLoca, renderStringToReact } from './utils';
export {
  ELanguage,
  ETagType,
  LanguageActions,
  LanguageProvider,
  LanguageReducer,
  LanguageSaga,
  LanguageSelectors,
  LanguageService,
  parseMultilineLoca,
  renderMultilineLoca,
  renderStringToReact,
  useDocumentLang,
  useTranslation,
};
export type { TFunction };
