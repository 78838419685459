import BannerSection, { IBannerSectionProps, TBannerSectionDirection } from './BannerSection';
import HeroSection, { IHeroSectionProps } from './HeroSection';
import Section, { ESectionAlign, ESectionColor, ESectionType, ISectionProps } from './Section';
import SectionSubtitle from './SectionSubtitle';
import SectionTitle from './SectionTitle';

export {
  BannerSection,
  ESectionAlign,
  ESectionColor,
  ESectionType,
  HeroSection,
  Section,
  SectionSubtitle,
  SectionTitle,
};
export type { IBannerSectionProps, IHeroSectionProps, ISectionProps, TBannerSectionDirection };
