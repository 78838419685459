import * as React from 'react';
import { lazy, Suspense } from 'react';
import { Redirect, Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { isLogout } from './apps/logout/utils';
import Loading from './components/loading/Loading';
import { ForgotUrls, LogoutUrls, PasswordUrls, SigninUrls, SignupUrls } from './core/constants';
import history from './services/historyService';
import * as ThemeManager from './services/themeManager';
import urlManager from './services/urlManager';

const subscribeWidget = lazy(() => import('./widgets/subscribe'));
const OpxpRegistration = lazy(() => import('./apps/opxpSignup/OpxpRegistration'));
const OpxpLogin = lazy(() => import('./apps/opxpLogin/OpxpLogin'));
const OpxpPasswordForgot = lazy(() => import('./apps/opxpForgot/OpxpForgotApp'));
const OpxpPasswordApp = lazy(() => import('./apps/opxpPassword/OpxpPasswordApp'));
const LogoutApp = lazy(() => import('./apps/logout/LogoutApp'));
const MobilePageApp = lazy(() => import('./apps/mobilePage'));
const CouponApp = lazy(() => import('./apps/coupon'));
const LogoutDiscountApp = lazy(() => import('./apps/logoutDiscount'));
const LegalsApp = lazy(() => import('./apps/legals/LegalsApp'));

export const Root: React.FC = () => {
  const theme = ThemeManager.Themes.Opxp;

  const redirectLogout = isLogout();
  const lang = urlManager.getSearchParam('lang');
  let logoutUrl = redirectLogout ? `${LogoutUrls[theme]}/${redirectLogout}` : null;
  if (logoutUrl && lang) {
    logoutUrl += `/?lang=${lang}`;
  }
  return (
    <ThemeManager.ThemeProvider value={theme}>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          {logoutUrl && <Redirect to={logoutUrl} />}
          <Switch>
            <Route exact={true} path="/subscribe" component={subscribeWidget} />
            <Route exact={true} path="/coupon" component={CouponApp} />
            <Route exact={true} path={urlManager.getLogoutDiscountUrl()} component={LogoutDiscountApp} />
            <Route path="/opxp">
              <Switch>
                <Route exact={true} path={`${SignupUrls.opxp}/:step?`} component={OpxpRegistration} />
                <Route exact={true} path={SigninUrls.opxp} component={OpxpLogin} />
                <Route exact={true} path={ForgotUrls.opxp} component={OpxpPasswordForgot} />
                <Route exact={true} path={PasswordUrls.opxp} component={OpxpPasswordApp} />
                <Route path={`${LogoutUrls.opxp}/:mode`} component={LogoutApp} />
                <Route path="/" component={OpxpLogin} />
              </Switch>
            </Route>
            <Route exact={true} path={`${SignupUrls.default}/:step?`} component={OpxpRegistration} />
            <Route exact={true} path={ForgotUrls.default} component={OpxpPasswordForgot} />
            <Route exact={true} path={PasswordUrls.default} component={OpxpPasswordApp} />
            <Route exact={true} path="/legals/:type" component={LegalsApp} />
            <Route exact={true} path="/mobile/:page" component={MobilePageApp} />
            <Route path={`${LogoutUrls.default}/:mode`} component={LogoutApp} />
            <Route path={SigninUrls.default} component={OpxpLogin} />
          </Switch>
        </Suspense>
      </Router>
    </ThemeManager.ThemeProvider>
  );
};

export default Root;
