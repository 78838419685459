import { moderatorMailWhiteList } from './constansts';
import { getRoleExpirationDate, hasGroupAdminRole, hasModeratorRole, hasPremiumRole, hasRole, Role } from './roles';
import * as Reducer from './slice';
import { getCacheBustedUrl } from './utils';

export interface IState {
  // this feature state
  [Reducer.statePropName]: Reducer.IAuthState;
}

export const getAuthState = (state: IState) => state[Reducer.statePropName];

export const getUserId = (state: IState) => getAuthState(state).user?.userId;

export const getToken = (state: IState) => getAuthState(state).session;

export const isAuthorized = (state: IState) => getAuthState(state).authorized;

export const isLoggedIn = (state: IState) => !!getAuthState(state).user?.userId;

export const getCreated = (state: IState) => getAuthState(state).user?.created;

export const getUserEmail = (state: IState) => {
  const user = getAuthState(state).user;
  return user ? user.mail : '';
};

export const getUserName = (state: IState) => {
  const user = getAuthState(state).user;
  return user ? user.name : '';
};

export const getUserPicture = (state: IState) => {
  const user = getAuthState(state).user;
  if (user && user.picture) {
    return getCacheBustedUrl(user.picture);
  }
  return undefined;
};

export const getUserLanguage = (state: IState) => {
  const user = getAuthState(state).user;
  return user ? user.language : '';
};

export const getUserObject = (state: IState) => {
  return getAuthState(state).user;
};

export const isPremium = (state: IState) => {
  const user = getAuthState(state).user;
  return user ? hasPremiumRole(user) : false;
};

const checkModeratorMail = (state: IState) => {
  const userMail = getUserEmail(state);
  if (!userMail) return false;
  return moderatorMailWhiteList.some((mailDomain) => userMail.indexOf(mailDomain) !== -1);
};

export const isModerator = (state: IState) => {
  const user = getAuthState(state).user;
  const isModeratorMail = checkModeratorMail(state);
  return user ? hasModeratorRole(user) || isModeratorMail : false;
};

export const isGroupAdmin = (state: IState) => {
  const user = getAuthState(state).user;
  return user ? hasGroupAdminRole(user) : false;
};

export const getUnreadMessages = (state: IState) => {
  const regular = getAuthState(state).regular;
  const system = getAuthState(state).system;
  return regular + system;
};

export const getRoleExpiration = (state: IState, role: Role) => {
  const user = getAuthState(state).user;
  return user ? getRoleExpirationDate(user, role) : undefined;
};

export const getPremiumEndTime = (state: IState) => {
  return getRoleExpiration(state, Role.WEBPREMIUM);
};

export const getUserRoles = (state: IState) => {
  return getAuthState(state).user?.roles;
};

export const userHasRole = (state: IState, role: Role) => {
  const user = getAuthState(state).user;
  return user ? hasRole(user, role) : false;
};

export const getFriendRequests = (state: IState) => getAuthState(state).friendship;
