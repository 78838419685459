import {
  AuthenticationCredential,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationCredential,
} from './types';
import { convertCreationOptions, convertCredentialRequest } from './utils';

export async function isBrowserSupported() {
  // Availability of `window.PublicKeyCredential` means WebAuthn is usable.
  // `isUserVerifyingPlatformAuthenticatorAvailable` means the feature detection is usable.
  // `isConditionalMediationAvailable` means the feature detection is usable.
  if (
    window.PublicKeyCredential &&
    PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable &&
    PublicKeyCredential.isConditionalMediationAvailable
  ) {
    // Check if user verifying platform authenticator is available.
    const results = await Promise.all([
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
      PublicKeyCredential.isConditionalMediationAvailable(),
    ]);
    if (results.every((r) => r === true)) {
      console.log('PassKey: user verifying platform authenticator is available.');
      return true;
    }
    console.log('PassKey: user verifying platform authenticator is not available.');
    return false;
  }
  console.log('PassKey: PublicKeyCredential not available');
  return false;
}

export async function createNewPasskey(publicKey: PublicKeyCredentialCreationOptionsJSON) {
  console.log('createNewPasskey start');
  const options = convertCreationOptions(publicKey);
  console.log('converted options', options);
  const credential = await navigator.credentials.create(options);
  console.log('returned credentials', credential);
  return credential as RegistrationCredential;
}

export async function loginWithPasskey(publicKey: PublicKeyCredentialRequestOptionsJSON) {
  console.log('loginWithPasskey start');
  const options = convertCredentialRequest(publicKey);
  console.log('converted options', options);
  const credential = await navigator.credentials.get(options);
  console.log('returned credentials', credential);
  return credential as AuthenticationCredential;
}

const AuthService = {
  isBrowserSupported,
  createNewPasskey,
  loginWithPasskey,
};
export default AuthService;
