import { Popover, PopoverInteractionKind, Position } from '@blueprintjs/core';
import * as React from 'react';

import './UserMenu.scss';

import Badge from '../badge';
import { NavBar, NavItem } from '../navBar';
import UserAvatar from '../userAvatar';

export interface IUserMenuProps {
  badge?: number | string;
  disabled?: boolean;
  label: string;
  picture?: string;
  onMenuClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const UserMenu: React.FC<React.PropsWithChildren<IUserMenuProps>> = ({
  badge,
  disabled = false,
  label,
  picture,
  onMenuClick,
  children,
}) => {
  const menuBadge = badge ? (
    <Badge className="user-menu-badge" color="green">
      {badge}
    </Badge>
  ) : null;
  const buttonClass = badge ? 'user-menu-button has-badge' : 'user-menu-button';
  return (
    <NavItem className="user-menu">
      <Popover
        disabled={disabled}
        usePortal={false}
        className="user-menu-popover"
        interactionKind={PopoverInteractionKind.HOVER}
        position={Position.BOTTOM_RIGHT}>
        <button className={buttonClass} onClick={onMenuClick}>
          <span className="avatar-container">
            <UserAvatar picture={picture} size="small" />
            <span className="menu-badge">{menuBadge}</span>
          </span>
          <span className="user-menu-label">{label}</span>
        </button>
        <NavBar vertical={true} className="user-menu-container">
          {children}
        </NavBar>
      </Popover>
    </NavItem>
  );
};

export default UserMenu;
