import * as Reducer from './slice';

export interface IAppState {
  // this feature state
  [Reducer.statePropName]: Reducer.IExperimentsState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getFeatureState = (state: IAppState) => state[Reducer.statePropName];

export const isLoading = (state: IAppState) => getFeatureState(state).loading;

export const getExperiments = (state: IAppState) => getFeatureState(state).experiments;

const getExperiment = (state: IAppState, experimentId: number) => {
  const experiments = getExperiments(state);
  return experiments.find((experimentVariant) => experimentVariant.experiment_id === experimentId);
};

export const getExperimentVariant = (state: IAppState, experimentId: number) =>
  getExperiment(state, experimentId)?.experiment_variant;

export const isExperimentEnabled = (state: IAppState, experimentId: number) => {
  const variant = getExperimentVariant(state, experimentId);
  // variant = 0 means experiment disabled
  // if experiment not found - fallback to disabled state
  return typeof variant !== 'undefined' && variant !== 0;
};
