import { LottieAnimation } from '@web_opxp/lottie';
import * as React from 'react';
import './Gauge.scss';

export interface IGaugeProps {
  amount: number;
  id?: string;
  minLabel: string;
  maxLabel: string;
}

/** @deprecated use SpeedometerCard instead */
export const Gauge: React.FC<IGaugeProps> = ({ amount, id = Math.random().toString(), minLabel, maxLabel }) => {
  const lottieId = `gauge-${id}`;

  return (
    <div className="gauge">
      <LottieAnimation className="gauge-speedometer" id={lottieId} name="speedometer" stopOn={amount} />
      <div className="gauge-labels">
        <label className="gauge-label">{minLabel}</label>
        <label className="gauge-label">{maxLabel}</label>
      </div>
    </div>
  );
};

export default Gauge;
