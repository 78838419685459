import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { ApiService, TApiFetchResponse } from 'web_core_library';
import * as ActionTypes from './actionTypes';
import AuthService from './authService';
import CredentialsService from './credentialsService';
import { PasskeyAuthActions } from './slice';
import { AuthenticationCredential, RegistrationCredential, TSendRegistrationPayload } from './types';
import { parseAuthenticationResponse, parseRegistrationResponse } from './utils';

export default function* passkeyAuthWatcher() {
  yield all([
    takeEvery(PasskeyAuthActions.initPasskey, initFeatureSaga),
    takeLeading(PasskeyAuthActions.startLogin, startLoginSaga),
    takeLeading(PasskeyAuthActions.browserLoginSuccess, finishLoginSaga),
    takeLeading(PasskeyAuthActions.startRegistration, startRegistrationSaga),
    takeLeading(PasskeyAuthActions.requestListOfPasskeys, startLoadingOfPasskeysSaga),
    takeLeading(PasskeyAuthActions.deleteUsersPasskey, deleteUsersPasskeySaga),
    takeLeading(PasskeyAuthActions.upgradeUserToPasskey, upgradeUserToPasskeySaga),
  ]);
}

export function* initFeatureSaga() {
  yield call(CredentialsService.init, ApiService);
  const isSupported: boolean = yield call(AuthService.isBrowserSupported);
  yield put(PasskeyAuthActions.featureReady(isSupported));
}

export function* startLoginSaga() {
  try {
    console.log('start restore');
    const credentialsFromApi: TApiFetchResponse<typeof CredentialsService.getLoginCredentials> = yield call(
      CredentialsService.getLoginCredentials
    );
    console.log('api result received', credentialsFromApi.data);
    const browserCredentials: AuthenticationCredential | null = yield call(
      AuthService.loginWithPasskey,
      credentialsFromApi.data
    );
    console.log('browser response received', browserCredentials);
    if (!browserCredentials) {
      yield put(PasskeyAuthActions.loginFailed('Credentials empty!'));
      return;
    }
    yield put(PasskeyAuthActions.browserLoginSuccess(browserCredentials));
  } catch (error) {
    console.log(error);
    yield put(PasskeyAuthActions.loginFailed((error as Error).message));
  }
}

export function* finishLoginSaga({ payload }: ActionTypes.TBrowserLoginSuccessAction) {
  try {
    const parsedCredentials = parseAuthenticationResponse(payload);
    const result: TApiFetchResponse<typeof CredentialsService.sendLoginCredentials> = yield call(
      CredentialsService.sendLoginCredentials,
      parsedCredentials
    );
    console.log('post login request done:', result.data);
    yield put(PasskeyAuthActions.loginSuccess(result.data));
  } catch (error) {
    console.log(error);
    yield put(PasskeyAuthActions.loginFailed((error as Error).message));
  }
}

export function* startRegistrationSaga({ payload }: ActionTypes.TStartRegistrationAction) {
  try {
    console.log('startRegistrationSaga');
    const credentialsFromApi: TApiFetchResponse<typeof CredentialsService.getRegistrationCredentials> = yield call(
      CredentialsService.getRegistrationCredentials,
      payload.email
    );
    console.log('api result received', credentialsFromApi.data);
    const browserCredentials: RegistrationCredential | null = yield call(
      AuthService.createNewPasskey,
      credentialsFromApi.data
    );
    console.log('browser result received', browserCredentials);
    if (!browserCredentials) {
      yield put(PasskeyAuthActions.registrationFailed('Credentials empty!'));
      return;
    }
    const parsedCredentials = parseRegistrationResponse(browserCredentials);
    const { email, language, referTo, attribution, couponToken } = payload;
    console.log('parsedCredentials', parsedCredentials);
    const regPayload: TSendRegistrationPayload = {
      ...parsedCredentials,
      language,
      referTo,
      attribution,
      couponToken,
      mail: email,
    };
    console.log('sending payload to api:', regPayload);
    const result: TApiFetchResponse<typeof CredentialsService.sendRegistrationCredentials> = yield call(
      CredentialsService.sendRegistrationCredentials,
      regPayload
    );
    console.log(result);
    yield put(PasskeyAuthActions.registrationDone(result.data));
  } catch (error) {
    console.log(error);
    yield put(PasskeyAuthActions.registrationFailed((error as Error).message));
  }
}

export function* startLoadingOfPasskeysSaga({ payload }: ActionTypes.TStartLoadingPasskeysAction) {
  try {
    const credentialsFromApi: TApiFetchResponse<typeof CredentialsService.getListOfPasskeys> = yield call(
      CredentialsService.getListOfPasskeys,
      payload
    );
    console.log('api result received', credentialsFromApi.data);
    yield put(PasskeyAuthActions.updateListOfPasskeys(credentialsFromApi.data));
  } catch (error) {
    // TODO: implement error handling
  }
}

export function* deleteUsersPasskeySaga({ payload }: ActionTypes.TDeleteUsersPasskeyAction) {
  const { key, userId } = payload;
  try {
    yield call(CredentialsService.deleteUsersPasskey, userId, key);
    // TODO: remove key from the list
  } catch (error) {
    // TODO: implement error handling
  }
}

export function* upgradeUserToPasskeySaga({ payload }: ActionTypes.TUpgradeUserToPasskeyAction) {
  try {
    const apiCredentials: TApiFetchResponse<typeof CredentialsService.requestUserUpgrade> = yield call(
      CredentialsService.requestUserUpgrade,
      payload
    );
    console.log('received credentials from server:', apiCredentials.data);
    const browserCredentials: RegistrationCredential | null = yield call(
      AuthService.createNewPasskey,
      apiCredentials.data
    );
    console.log('browser result received', browserCredentials);
    if (!browserCredentials) {
      // TODO: implement handling
      return;
    }
    // TODO: implement sending results to api
    const parsedCredentials = parseRegistrationResponse(browserCredentials);
    console.log('parsedCredentials', parsedCredentials);
    const result: TApiFetchResponse<typeof CredentialsService.saveUserPasskeyUpgrade> = yield call(
      CredentialsService.saveUserPasskeyUpgrade,
      payload,
      parsedCredentials
    );
    console.log(result);
  } catch (error) {
    // TODO: implement error handling
  }
}
