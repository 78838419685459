import {
  ensureInitialized,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
  isSupported,
  RemoteConfig,
} from 'firebase/remote-config';
import { getApp } from './app';
import DefaultConfig from './remote_config_defaults.json';
import { TRemoteConfigValue } from './types';

let configApp: RemoteConfig | undefined;

function wrapFallbackValue<T extends TRemoteConfigValue>(value: T): string | number | boolean {
  if (typeof value === 'object') {
    return JSON.stringify(value);
  }
  return value;
}

/**
 * Core firebase app must be initialized before you call this
 * otherwise it will throw an error
 */
export const init = async () => {
  try {
    const isConfigSupported = await isSupported();
    if (!isConfigSupported) {
      console.warn('RemoteConfig app is not supported by this browser!');
      return;
    }
    const app = getApp();
    configApp = getRemoteConfig(app);
    configApp.defaultConfig = DefaultConfig;
    configApp.settings = {
      minimumFetchIntervalMillis: 1000 * 60 * 5, // 5 min
      fetchTimeoutMillis: 1000 * 30, // 30 sec
    };
    await ensureInitialized(configApp);
  } catch (error) {
    return;
  }
};
export const fetchConfig = async () => {
  if (!configApp) {
    return;
  }
  try {
    const fetchResult = await fetchAndActivate(configApp);
    return fetchResult;
  } catch (error) {
    // Log the error and simply return to let the defaults be used
    console.error(error);
    return;
  }
};
const getConfigValue = <T extends TRemoteConfigValue>(key: string, fallbackValue?: T) => {
  if (!configApp) {
    console.warn('RemoteConfig not initialized! Trying to get value for key', key);
    throw new Error('RemoteConfig not initialized!');
  }
  if (fallbackValue) {
    configApp.defaultConfig[key] = wrapFallbackValue(fallbackValue);
  }
  return getValue(configApp, key);
};
export const getBooleanConfigValue = <T extends TRemoteConfigValue>(key: string, fallbackValue?: T) =>
  getConfigValue(key, fallbackValue).asBoolean();
export const getStringConfigValue = <T extends TRemoteConfigValue>(key: string, fallbackValue?: T) =>
  getConfigValue(key, fallbackValue).asString();
export const getNumberConfigValue = <T extends TRemoteConfigValue>(key: string, fallbackValue?: T) =>
  getConfigValue(key, fallbackValue).asNumber();
export const getObjectConfigValue = <T extends TRemoteConfigValue>(key: string, fallbackValue?: T) => {
  const strValue = getConfigValue(key, fallbackValue).asString();
  try {
    const returnValue = JSON.parse(strValue) as T;
    return returnValue;
  } catch (error) {
    return fallbackValue;
  }
};
