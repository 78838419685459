import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as ActionTypes from './actionTypes';
import { ILoginResponsePayload, TListOfUsersPasskeys } from './types';

export const statePropName = 'passkeyAuth';

export interface IPasskeyAuthState {
  // null - means feature did not started yet
  // true/false if feature available for current user
  ready: boolean;
  started: boolean;
  loading: boolean;
  error?: string;
  passkeys?: TListOfUsersPasskeys;
}

export const initialState: IPasskeyAuthState = {
  ready: false,
  started: false,
  loading: false,
};

const passkeyAuthSlice = createSlice({
  name: statePropName,
  initialState,
  reducers: {
    initPasskey: () => {
      return { ...initialState };
    },
    featureReady: (state: IPasskeyAuthState, action: ActionTypes.TFeatureReadyAction) => {
      return {
        ...state,
        ready: action.payload,
        started: true,
      };
    },
    startLogin: (state: IPasskeyAuthState) => {
      return {
        ...state,
        loading: true,
      };
    },
    browserLoginSuccess: (state: IPasskeyAuthState, action: ActionTypes.TBrowserLoginSuccessAction) => {},
    loginFailed: (state: IPasskeyAuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    loginSuccess: (state: IPasskeyAuthState, action: PayloadAction<ILoginResponsePayload>) => {
      return {
        ...state,
        loading: false,
      };
    },
    startRegistration: (state: IPasskeyAuthState, action: ActionTypes.TStartRegistrationAction) => {
      return {
        ...state,
        loading: true,
      };
    },
    registrationFailed: (state: IPasskeyAuthState, action: PayloadAction<string>) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    registrationDone: (state: IPasskeyAuthState, action: PayloadAction<unknown>) => {
      return {
        ...state,
        loading: false,
      };
    },
    requestListOfPasskeys: (state: IPasskeyAuthState, action: ActionTypes.TStartLoadingPasskeysAction) => {
      return {
        ...state,
        loading: true,
      };
    },
    updateListOfPasskeys: (state: IPasskeyAuthState, action: PayloadAction<TListOfUsersPasskeys>) => {
      return {
        ...state,
        loading: false,
        passkeys: action.payload,
      };
    },
    deleteUsersPasskey: (state: IPasskeyAuthState, action: ActionTypes.TDeleteUsersPasskeyAction) => {
      return {
        ...state,
        loading: true,
      };
    },
    upgradeUserToPasskey: (state: IPasskeyAuthState, action: ActionTypes.TUpgradeUserToPasskeyAction) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
});

export const PasskeyAuthActions = passkeyAuthSlice.actions;
export default passkeyAuthSlice.reducer;
