import { SentryService, isLocalhost, isProduction } from '@web_opxp/features';
import { createRoot } from 'react-dom/client';
import Root from './Root';
import { BuildID } from './core/env';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

if (!isLocalhost) {
  SentryService.initSentry(isProduction(), BuildID);
}

const container = document.getElementById('root');
if (!container) {
  throw new Error('Incorrect template. Application root not found on the page!');
}
const root = createRoot(container);
root.render(<Root />);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
