import { AnchorButton, CAnchorButtonClassname, EAnchorButtonColor, IAnchorButtonProps } from './lib/anchorButton';
import AppHeader from './lib/appHeader/AppHeader';
import { IActionNavItem, IHrefNavItem, ILinkNavItem, IMenuNavItem, ISubmenuNavItem } from './lib/appHeader/types';
import { Badge, IBadgeProps } from './lib/badge/Badge';
import Banner from './lib/banner/Banner';
import { useDoubleClickProtection } from './lib/button';
import Button, { CButtonClassname, EButtonColor, IButtonProps } from './lib/button/Button';
import Carousel from './lib/carousel/Carousel';
import Checkbox, { ECheckboxStyle, ICheckboxProps } from './lib/checkbox/Checkbox';
import { CircleProgressBar } from './lib/circleProgress/CircleProgressBar';
import { IProgressItemProps, ProgressItem } from './lib/circleProgress/ProgressItem';
import { ProgressItemState } from './lib/circleProgress/types';
import CloseButton from './lib/dialog/CloseButton';
import Dialog, { IDialogProps } from './lib/dialog/Dialog';
import Footer from './lib/footer/Footer';
import { IFooterMenuItem, ILegalLink, ILegalLinks } from './lib/footer/types';
import { Gauge, IGaugeProps } from './lib/gauge/Gauge';
import { BurgerIcon, CloseIcon } from './lib/header';
import type { IHeaderProps } from './lib/header/Header';
import Header from './lib/header/Header';
import Header2 from './lib/header/Header2';
import StringInput, { EInputColor, IStringInputProps } from './lib/input/StringInput';
import { LANGUAGES } from './lib/languageSelector';
import LanguageSelector, { ILanguageSelectorProps } from './lib/languageSelector/LanguageSelector';
import LoadingSpinner, { ILoadingSpinnerProps } from './lib/loadingSpinner/LoadingSpinner';
import NavBar, { INavBarProps } from './lib/navBar/NavBar';
import NavItem, { INavItemProps } from './lib/navBar/NavItem';
import { INavItem } from './lib/navBar/types';
import NavBar2 from './lib/navBar2/NavBar2';
import NavItem2 from './lib/navBar2/NavItem2';
import NavMenu2 from './lib/navBar2/NavMenu2';
import BannerSection, { IBannerSectionProps, TBannerSectionDirection } from './lib/sections/BannerSection';
import HeroSection, { IHeroSectionProps } from './lib/sections/HeroSection';
import Section, { ESectionAlign, ESectionColor, ESectionType, ISectionProps } from './lib/sections/Section';
import SectionSubtitle from './lib/sections/SectionSubtitle';
import SectionTitle from './lib/sections/SectionTitle';
import SocialBar, { ISocialBarProps, ISocialIconProps } from './lib/SocialBar/SocialBar';
import SpeedometerCard, { ISpeedometerCardProps } from './lib/SpeedometerCard/SpeedometerCard';
import './lib/styles.scss';
import TrustBanner, { ITrustBannerItem, ITrustBannerProps } from './lib/trustBanner/TrustBanner';
import UserAvatar, { IUserAvatarProps } from './lib/userAvatar/UserAvatar';
import UserMenu, { IUserMenuProps } from './lib/userMenu/UserMenu';
import UserMenuItem, { IBaseMenuItem, IUserMenuItemProps } from './lib/userMenu/UserMenuItem';
// re-export components and types
export {
  AnchorButton,
  AppHeader,
  Badge,
  Banner,
  BannerSection,
  BurgerIcon,
  Button,
  CAnchorButtonClassname,
  Carousel,
  CButtonClassname,
  Checkbox,
  CircleProgressBar,
  CloseButton,
  CloseIcon,
  Dialog,
  EAnchorButtonColor,
  EButtonColor,
  ECheckboxStyle,
  EInputColor,
  ESectionAlign,
  ESectionColor,
  ESectionType,
  Footer,
  Gauge,
  Header,
  Header2,
  HeroSection,
  LANGUAGES,
  LanguageSelector,
  LoadingSpinner,
  NavBar,
  NavBar2,
  NavItem,
  NavItem2,
  NavMenu2,
  ProgressItem,
  ProgressItemState,
  Section,
  SectionSubtitle,
  SectionTitle,
  SocialBar,
  StringInput,
  TrustBanner,
  useDoubleClickProtection,
  UserAvatar,
  UserMenu,
  UserMenuItem,
};
export type {
  IActionNavItem,
  IAnchorButtonProps,
  IBadgeProps,
  IBannerSectionProps,
  IBaseMenuItem,
  IButtonProps,
  ICheckboxProps,
  IDialogProps,
  IFooterMenuItem,
  IGaugeProps,
  IHeaderProps,
  IHeroSectionProps,
  IHrefNavItem,
  ILanguageSelectorProps,
  ILegalLink,
  ILegalLinks,
  ILinkNavItem,
  ILoadingSpinnerProps,
  IMenuNavItem,
  INavBarProps,
  INavItem,
  INavItemProps,
  IProgressItemProps,
  ISectionProps,
  ISocialBarProps,
  ISocialIconProps,
  IStringInputProps,
  ISubmenuNavItem,
  ITrustBannerItem,
  ITrustBannerProps,
  IUserAvatarProps,
  IUserMenuItemProps,
  IUserMenuProps,
  TBannerSectionDirection,
};
// new version of GaugeCard with lottie hardcoded for better performance
export { SpeedometerCard };
export type { ISpeedometerCardProps };
