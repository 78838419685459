import { Icon, IconName } from '@blueprintjs/core';
import * as React from 'react';

import Badge from '../badge';
import { NavItem } from '../navBar';

export interface IBaseMenuItem {
  label: string;
  icon?: IconName;
  badge?: number | string;
}

export interface IUserMenuItemProps extends IBaseMenuItem {
  link?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const UserMenuItem: React.FC<IUserMenuItemProps> = ({ badge, icon, label, link, onClick }) => {
  const menuBadge = badge ? (
    <Badge className="user-menu-item-badge" color="blue">
      {badge}
    </Badge>
  ) : null;
  const menuIcon = icon ? <Icon className="user-menu-item-icon" icon={icon} /> : null;
  const itemContent = (
    <>
      {menuIcon}
      <span className="user-menu-item-label">{label}</span>
      {menuBadge}
    </>
  );
  const menuItem = link ? (
    <a title={label} onClick={onClick} href={link}>
      {itemContent}
    </a>
  ) : (
    <button title={label} onClick={onClick}>
      {itemContent}
    </button>
  );
  return <NavItem className="user-menu-item">{menuItem}</NavItem>;
};

export default UserMenuItem;
